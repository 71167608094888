import React, { useEffect, useState } from "react"
import { FaFileCode, FaFolderPlus, FaUserPlus, FaUserMinus } from "react-icons/fa"
import { RiLoader2Line } from "react-icons/ri"
import {
    IoMdBuild,
    IoMdClose,
    IoMdCodeWorking,
    IoMdFolder,
    IoMdFolderOpen,
    IoMdShare,
} from "react-icons/io"
import { useHistory, useParams } from "react-router"
import SLMan from "../../SLMan"
import Toaster from "../Toaster"
import { SnippetEditor } from "./SnippetEditor"
import CopyToClipboard from "react-copy-to-clipboard"

export const Browser = () => {
    const props = useParams()

    const history = useHistory()
    const [loading, setLoading] = useState(true)

    const [files, setFiles] = useState({})
    const [folders, setFolders] = useState({})

    const [currentProject, setCurrentProject] = useState("")
    const [currentProjectName, setCurrentProjectName] = useState("")
    const [currentSnippet, setCurrentSnippet] = useState("")

    const [parent, setParent] = useState("")

    const [currentEditFolder, setCurrentEditFolder] = useState("")
    const [currentEditFolderName, setCurrentEditFolderName] = useState("")
    const [currentFolderConfirm, setCurrentFolderConfirm] = useState(false)

    const [currentlyDragging, setCurrentlyDragging] = useState({})

    const [currentEditItem, setCurrentEditItem] = useState(null)

    useEffect(() => {
        reloadView()
    }, [props])

    const reloadView = () => {
        setLoading(true)

        let folder = ""
        let newFile = ""

        const url = props[0].substr("/app/".length)
        if (url) {
            if (url.includes("/")) {
                folder = url.split("/")[0]
                newFile = url.split("/")[1]
                if (folder == "-") folder = ""
            } else {
                folder = url
            }
        }

        setCurrentProject(folder)
        if (newFile) {
            setCurrentSnippet(newFile)
        }

        SLMan.requestAPI(
            "ext/snippetland/browser?user=" + SLMan.UserData.userid + "&folder=" + folder,
            (result) => {
                if (result.error) {
                    console.error(result.errorID, result.errorMessage)
                    Toaster.error("Failed", result.errorMessage)
                } else {
                    var list = result.data.list
                    setCurrentProjectName(result.data.parentName)
                    setParent(result.data.parent)
                    var _folders = {}
                    var _files = {}
                    list.map((item) => {
                        if (item.folder) {
                            _folders[item.id] = {
                                title: item.title,
                                shared: item.shared,
                                owns: item.owns,
                            }
                        } else {
                            _files[item.id] = {
                                title: item.title,
                                desc: item.subtitle,
                                shared: item.shared,
                                owns: item.owns,
                            }
                        }
                    })
                    setFolders(_folders)
                    setFiles(_files)
                    setLoading(false)
                }
            }
        )
    }

    const openFolder = (folderID) => {
        history.push("/app/" + folderID)
    }

    const openFile = (fileID) => {
        if (currentProject) history.push("/app/" + currentProject + "/" + fileID)
        else history.push("/app/-/" + fileID)
    }

    const updateFoldername = (folderID, name) => {
        if (Object.keys(folders).includes(folderID)) {
            setFolders({
                ...folders,
                [folderID]: {
                    ...folders[folderID],
                    title: name,
                },
            })
            SLMan.requestAPI(
                "ext/snippetland/renamefolder?user=" +
                    SLMan.UserData.userid +
                    "&folder=" +
                    folderID +
                    "&title=" +
                    encodeURIComponent(name),
                (result) => {
                    if (result.error) {
                        console.error(result.error, result.errorMessage)
                        Toaster.error("Failed", result.errorMessage)
                    } else {
                        reloadView()
                        Toaster.success("Success", "Folder renamed successfully")
                    }
                }
            )
            setCurrentEditFolder("")
            setCurrentEditFolderName("")
            setCurrentFolderConfirm(false)
        }
    }

    const updateFilename = (fileID, name) => {
        if (Object.keys(files).includes(fileID)) {
            setFiles({
                ...files,
                [fileID]: {
                    ...files[fileID],
                    title: name,
                },
            })
        }
    }

    const newSnippet = () => {
        SLMan.requestAPI(
            "ext/snippetland/newfile?user=" +
                SLMan.UserData.userid +
                (currentProject === "" ? "" : "&folder=" + currentProject),
            (result) => {
                if (result.error) {
                    console.error(result.error, result.errorMessage)
                    Toaster.error("Failed", result.errorMessage)
                } else {
                    reloadView()
                    Toaster.success("Success", "Snippet created successfully")
                }
            }
        )
    }

    const newFolder = () => {
        SLMan.requestAPI(
            "ext/snippetland/newfolder?user=" +
                SLMan.UserData.userid +
                (currentProject === "" ? "" : "&folder=" + currentProject),
            (result) => {
                if (result.error) {
                    console.error(result.error, result.errorMessage)
                    Toaster.error("Failed", result.errorMessage)
                } else {
                    reloadView()
                    Toaster.success("Success", "Folder created successfully")
                }
            }
        )
    }

    const openFolderSettings = (folder, folderName) => {
        setCurrentEditFolder(folder)
        setCurrentEditFolderName(folderName)
        setCurrentFolderConfirm(false)
    }

    const deleteFolder = (folder) => {
        if (Object.keys(folders).includes(folder)) {
            SLMan.requestAPI(
                "ext/snippetland/deletefolder?user=" + SLMan.UserData.userid + "&id=" + folder,
                (result) => {
                    if (result.error) {
                        console.error(result.error, result.errorMessage)
                        Toaster.error("Failed", result.errorMessage)
                    } else {
                        reloadView()
                        Toaster.success("Success", "Folder deleted successfully")
                    }
                }
            )
        }
        setCurrentEditFolder("")
        setCurrentEditFolderName("")
        setCurrentFolderConfirm(false)
    }

    const move = (from, to) => {
        if (from === to) return
        setLoading(true)
        SLMan.requestAPI(
            "ext/snippetland/move?user=" + SLMan.UserData.userid + "&from=" + from + "&to=" + to,
            (result) => {
                setLoading(false)
                if (result.error) {
                    console.error(result.error, result.errorMessage)
                    Toaster.error("Failed", result.errorMessage)
                } else {
                    if (Object.keys(files).includes(from)) {
                        var _tmp = files
                        delete _tmp[from]
                        setFiles(_tmp)
                    }
                    if (Object.keys(folders).includes(from)) {
                        var _tmp2 = folders
                        delete _tmp2[from]
                        setFolders(_tmp2)
                    }
                    reloadView()
                    Toaster.success("Success", "File / Folder moved")
                }
            }
        )
    }

    return (
        <div className="flex w-full h-full lg:h-screen">
            <div className="w-screen md:w-60 lg:w-72 h-full bg-white overflow-y-auto">
                {/** SHARE WINDOW */}
                {currentEditItem && (
                    <SharePanel
                        {...currentEditItem}
                        close={(_) => setCurrentEditItem(null)}
                        onShare={(_) => {
                            const sharing = currentEditItem.id
                            if (currentEditItem.type === "folder")
                                setFolders({
                                    ...folders,
                                    [sharing]: { ...folders[sharing], shared: true },
                                })
                            if (currentEditItem.type === "snippet")
                                setFiles({
                                    ...files,
                                    [sharing]: { ...files[sharing], shared: true },
                                })
                        }}
                        onUnShare={(_) => {
                            const sharing = currentEditItem.id
                            if (currentEditItem.type === "folder")
                                setFolders({
                                    ...folders,
                                    [sharing]: { ...folders[sharing], shared: false },
                                })
                            if (currentEditItem.type === "snippet")
                                setFiles({
                                    ...files,
                                    [sharing]: { ...files[sharing], shared: false },
                                })
                        }}
                        onClose={(_) => setCurrentEditItem(null)}
                    />
                )}

                {/** EDIT FOLDER */}
                <div
                    className={
                        "fixed top-0 left-0 h-full w-full bg-black bg-opacity-30 flex-col items-center justify-center z-20 " +
                        (currentEditFolder.length > 0 ? "flex" : "hidden")
                    }
                >
                    <div className="rounded bg-white shadow-md p-4 w-80 relative">
                        <button
                            onClick={(e) => setCurrentEditFolder("")}
                            className="absolute -right-11 top-0 rounded-full w-7 h-7 bg-white shadow-md flex justify-center items-center"
                        >
                            <IoMdClose />
                        </button>
                        <h2 className="font-extrabold text-2xl">Edit folder</h2>
                        <input
                            onKeyDown={(e) => {
                                if (e.key.toLowerCase() == "enter")
                                    updateFoldername(currentEditFolder, currentEditFolderName)
                                else if (e.key.toLowerCase() == "escape") setCurrentEditFolder("")
                            }}
                            type="text"
                            value={currentEditFolderName}
                            placeholder="Filename"
                            onChange={(e) => setCurrentEditFolderName(e.target.value)}
                            className="px-3 py-1 border border-gray-200 text-md mt-4 w-full"
                        />
                        <button
                            className="px-3 py-1 border border-purple-400 text-md mt-4 w-full"
                            onClick={(e) =>
                                updateFoldername(currentEditFolder, currentEditFolderName)
                            }
                        >
                            Rename folder
                        </button>
                        {!currentFolderConfirm ? (
                            <>
                                <button
                                    className="px-3 py-1 border border-red-400 text-xs mt-4 w-full"
                                    onClick={(e) => setCurrentFolderConfirm(true)}
                                >
                                    Delete folder with all content inside
                                </button>
                            </>
                        ) : (
                            <>
                                <button
                                    className="px-3 py-1 border border-red-400 bg-red-400 text-white text-xs mt-4 w-full"
                                    onClick={(e) => deleteFolder(currentEditFolder)}
                                >
                                    Delete folder with all content inside (Click again to confirm)
                                </button>
                            </>
                        )}
                    </div>
                </div>

                <div className="h-16 flex px-5 items-center font-extrabold text-2xl sticky top-0 bg-white">
                    <span className="overflow-hidden whitespace-nowrap overflow-ellipsis">
                        {currentProjectName ? currentProjectName : "SnippetLand"}
                    </span>
                </div>

                {loading ? (
                    <>
                        <div className="flex bg-gray-50">
                            <button className="flex-1 w-full py-2 px-4 flex items-center hover:bg-gray-100">
                                <span className="pr-2">
                                    <FaFileCode />
                                </span>{" "}
                                <span className="text-xs">New Snippet</span>
                            </button>
                            <button className="flex-1 w-full py-2 px-4 flex items-center hover:bg-gray-100">
                                <span className="pr-2">
                                    <FaFolderPlus />
                                </span>{" "}
                                <span className="text-xs">New Folder</span>
                            </button>
                        </div>

                        <div className="flex justify-center items-center h-72 w-full">
                            <RiLoader2Line className="text-xl opacity-40 animate-spin" />
                        </div>
                    </>
                ) : (
                    <>
                        <div className="flex bg-gray-50">
                            <button
                                className="flex-1 w-full py-2 px-4 flex items-center hover:bg-gray-100"
                                onClick={newSnippet}
                            >
                                <span className="pr-2">
                                    <FaFileCode />
                                </span>{" "}
                                <span className="text-xs">New Snippet</span>
                            </button>
                            <button
                                className="flex-1 w-full py-2 px-4 flex items-center hover:bg-gray-100"
                                onClick={newFolder}
                            >
                                <span className="pr-2">
                                    <FaFolderPlus />
                                </span>{" "}
                                <span className="text-xs">New Folder</span>
                            </button>
                        </div>

                        {currentProject && (
                            <div
                                onClick={(e) => {
                                    if (parent) history.push("/app/" + parent)
                                    else history.push("/app")
                                }}
                                onDragEnd={(event) => {
                                    event.preventDefault()
                                }}
                                onDragOver={(event) => {
                                    event.preventDefault()
                                }}
                                onDrop={() => {
                                    if (currentlyDragging !== null) {
                                        move(currentlyDragging.id, parent)
                                    }
                                }}
                                className="flex py-3 px-5 items-center cursor-pointer hover:bg-gray-100"
                            >
                                <span className="text-2xl pr-3">
                                    <IoMdFolderOpen />
                                </span>
                                <span className="overflow-hidden whitespace-nowrap overflow-ellipsis">
                                    [...]
                                </span>
                            </div>
                        )}

                        {Object.keys(folders).map((elI, index) => {
                            const el = folders[elI]
                            return (
                                <div
                                    key={index}
                                    draggable={el.owns || el.shared}
                                    onDragStart={() => {
                                        setCurrentlyDragging({
                                            type: "folder",
                                            id: elI,
                                        })
                                    }}
                                    onDragEnd={(event) => {
                                        event.preventDefault()
                                    }}
                                    onDragOver={(event) => {
                                        event.preventDefault()
                                    }}
                                    onDrop={() => {
                                        if (currentlyDragging !== null) {
                                            move(currentlyDragging.id, elI)
                                        }
                                    }}
                                    className="group flex py-3 px-5 items-center cursor-pointer hover:bg-gray-100"
                                >
                                    <span
                                        className="text-2xl pr-3"
                                        onClick={(e) => {
                                            openFolder(elI)
                                        }}
                                    >
                                        <IoMdFolder />
                                    </span>
                                    <span
                                        className="overflow-hidden whitespace-nowrap overflow-ellipsis flex-1"
                                        onClick={(e) => {
                                            openFolder(elI)
                                        }}
                                    >
                                        {el.title}
                                    </span>
                                    <span className="flex items-center">
                                        <span
                                            className="block ml-auto text-lg cursor-pointer pl-3 opacity-0 group-hover:opacity-40"
                                            onClick={(e) => openFolderSettings(elI, el.title)}
                                        >
                                            <IoMdBuild />
                                        </span>
                                        {el.shared && (
                                            <span
                                                className="block ml-auto text-lg cursor-pointer pl-1"
                                                onClick={(_) => {
                                                    setCurrentEditItem({
                                                        id: elI,
                                                        name: el.title,
                                                        type: "folder",
                                                        hasPerms: el.owns,
                                                    })
                                                }}
                                            >
                                                <IoMdShare />
                                            </span>
                                        )}
                                        {!el.shared && (
                                            <span
                                                className="block ml-auto text-lg cursor-pointer pl-1 opacity-0 group-hover:opacity-40"
                                                onClick={(_) => {
                                                    setCurrentEditItem({
                                                        id: elI,
                                                        name: el.title,
                                                        type: "folder",
                                                        hasPerms: el.owns,
                                                    })
                                                }}
                                            >
                                                <IoMdShare />
                                            </span>
                                        )}
                                    </span>
                                </div>
                            )
                        })}
                        {Object.keys(files).map((elI, index) => {
                            const el = files[elI]
                            return (
                                <div
                                    key={index}
                                    draggable={el.owns || el.shared}
                                    onDragStart={(_) => {
                                        setCurrentlyDragging({
                                            type: "snippet",
                                            id: elI,
                                        })
                                    }}
                                    className={
                                        "group flex py-3 px-5 items-center cursor-pointer hover:bg-gray-100" +
                                        (currentSnippet == elI ? " bg-gray-100" : "")
                                    }
                                >
                                    <span
                                        className="text-2xl pr-3"
                                        onClick={(e) => {
                                            openFile(elI)
                                        }}
                                    >
                                        <IoMdCodeWorking />
                                    </span>
                                    <span
                                        className="overflow-hidden whitespace-nowrap overflow-ellipsis"
                                        onClick={(e) => {
                                            openFile(elI)
                                        }}
                                    >
                                        {el.title}
                                    </span>
                                    {el.shared && (
                                        <span
                                            className="block ml-auto text-lg cursor-pointer pl-3"
                                            onClick={(_) => {
                                                setCurrentEditItem({
                                                    id: elI,
                                                    name: el.title,
                                                    type: "snippet",
                                                    hasPerms: el.owns,
                                                })
                                            }}
                                        >
                                            <IoMdShare />
                                        </span>
                                    )}
                                    {!el.shared && (
                                        <span
                                            className="block ml-auto text-lg cursor-pointer pl-3 opacity-0 group-hover:opacity-40"
                                            onClick={(_) => {
                                                setCurrentEditItem({
                                                    id: elI,
                                                    name: el.title,
                                                    type: "snippet",
                                                    hasPerms: el.owns,
                                                })
                                            }}
                                        >
                                            <IoMdShare />
                                        </span>
                                    )}
                                </div>
                            )
                        })}
                    </>
                )}
            </div>

            <div className="block flex-1 h-full shadow-inner w-full py-8 px-4">
                {currentSnippet && (
                    <SnippetEditor
                        id={currentSnippet}
                        saveAPI="save"
                        contentType="snippet"
                        onNameChange={(title, desc) => updateFilename(currentSnippet, title)}
                        onDelete={(id) => reloadView()}
                    />
                )}
            </div>
        </div>
    )
}

const SharePanel = (props) => {
    const [usersShared, setUsersShared] = useState([])
    const [shareLink, setShareLink] = useState(null)
    const [addUser, setAddUser] = useState("")
    const [options, setOptions] = useState({
        focusFile: "",
        hideTitle: false,
        hideTools: false,
    })

    useEffect(() => {
        if (props.hasPerms) reloadList(false)
    }, [props])

    const createShareURL = () => {
        let url = shareLink
        const opts = []
        if (options.focusFile) opts.push(`file=${options.focusFile}`)
        if (options.hideTitle) opts.push(`notitle`)
        if (options.hideTools) opts.push(`hidetools`)
        if (opts.length > 0) url += "?" + opts.join("&")
        return url
    }

    const reloadList = (check) => {
        SLMan.requestAPI(
            "ext/snippetland/sharedetails?user=" + SLMan.UserData.userid + "&snippet=" + props.id,
            (result) => {
                if (result.error) {
                    Toaster.error("Failed", result.errorMessage)
                } else {
                    setUsersShared(result.data.list)
                    if (result.data.link) setShareLink(result.data.link)
                    else setShareLink(null)
                    if (check) {
                        var l = result.data.list
                        if (l.length === 0 && !result.data.link) {
                            if (props.onUnShare) props.onUnShare()
                        } else {
                            if (props.onShare) props.onShare()
                        }
                    }
                }
            }
        )
    }

    const addUserButton = () => {
        SLMan.requestAPI(
            "ext/snippetland/addtoshared?user=" +
                SLMan.UserData.userid +
                "&snippet=" +
                props.id +
                "&new=" +
                encodeURIComponent(addUser),
            (result) => {
                if (result.error) {
                    Toaster.error("Failed", result.errorMessage)
                } else {
                    reloadList(true)
                }
                setAddUser("")
            }
        )
    }

    const removeUser = (username) => {
        SLMan.requestAPI(
            "ext/snippetland/removefromshared?user=" +
                SLMan.UserData.userid +
                "&snippet=" +
                props.id +
                "&remove=" +
                encodeURIComponent(username),
            (result) => {
                if (result.error) {
                    Toaster.error("Failed", result.errorMessage)
                } else {
                    reloadList(true)
                }
                setAddUser("")
            }
        )
    }

    const createLink = () => {
        SLMan.requestAPI(
            "ext/snippetland/createlink?user=" + SLMan.UserData.userid + "&snippet=" + props.id,
            (result) => {
                if (result.error) {
                    Toaster.error("Failed", result.errorMessage)
                } else {
                    reloadList(true)
                }
            }
        )
    }

    const deleteLink = () => {
        SLMan.requestAPI(
            "ext/snippetland/deletelink?user=" + SLMan.UserData.userid + "&snippet=" + props.id,
            (result) => {
                if (result.error) {
                    Toaster.error("Failed", result.errorMessage)
                } else {
                    reloadList(true)
                }
            }
        )
    }

    return (
        <div className="fixed top-0 left-0 h-full w-full bg-black bg-opacity-30 flex-col items-center justify-center z-20 flex">
            <div className="rounded bg-white shadow-md p-4 w-80 relative">
                <button
                    onClick={(e) => props.close()}
                    className="absolute -right-11 top-0 rounded-full w-7 h-7 bg-white shadow-md flex justify-center items-center"
                >
                    <IoMdClose />
                </button>
                <h2 className="font-extrabold text-2xl mb-4">Sharing</h2>
                {props.hasPerms ? (
                    <>
                        <div className="flex">
                            <input
                                type="text"
                                value={addUser}
                                placeholder="EMail"
                                onChange={(e) => setAddUser(e.target.value)}
                                className="px-3 py-1 border border-gray-200 text-md w-full border-r-0"
                            />
                            <button
                                className="px-3 py-1 border border-purple-600 text-md text-purple-600"
                                onClick={addUserButton}
                            >
                                <FaUserPlus />
                            </button>
                        </div>

                        {usersShared.map((user) => (
                            <div className="flex">
                                <input
                                    type="text"
                                    value={user}
                                    viewOnly
                                    disabled
                                    className="px-3 py-1 border border-gray-50 text-md w-full border-r-0"
                                />
                                <button
                                    className="px-3 py-1 border border-red-300 text-md text-red-300"
                                    onClick={(_) => {
                                        removeUser(user)
                                    }}
                                >
                                    <FaUserMinus />
                                </button>
                            </div>
                        ))}

                        {props.type === "snippet" && (
                            <>
                                <hr className="my-7" />

                                <p className="mb-2">
                                    You can create a public link, so everyone can view the Snippet.
                                </p>

                                {shareLink ? (
                                    <>
                                        <div className="flex">
                                            <CopyToClipboard
                                                text={"https://snippet.land/s/" + shareLink}
                                                onCopy={(_) =>
                                                    Toaster.success(
                                                        "Success",
                                                        "Copied to your clipboard"
                                                    )
                                                }
                                            >
                                                <button className="px-3 py-1 border border-purple-600  font-bold text-md text-purple-600">
                                                    Copy link
                                                </button>
                                            </CopyToClipboard>
                                            <button
                                                className="ml-2 px-3 py-1 border border-red-600 text-md text-red-600"
                                                onClick={deleteLink}
                                            >
                                                Delete link
                                            </button>
                                        </div>
                                        <hr className="my-7" />
                                        <p className="mb-2">
                                            You can embed the Snippet in websites or blogs:
                                        </p>
                                        <CopyToClipboard
                                            text={`<iframe src="https://snippet.land/s/${createShareURL()}" height="460" width="700" />`}
                                            onCopy={(_) =>
                                                Toaster.success(
                                                    "Success",
                                                    "Copied to your clipboard"
                                                )
                                            }
                                        >
                                            <button className="px-3 py-1 border border-gray-400  font-bold text-md text-gray-400">
                                                Copy HTML code
                                            </button>
                                        </CopyToClipboard>
                                        <CopyToClipboard
                                            text={`https://snippet.land/s/${createShareURL()}`}
                                            onCopy={(_) =>
                                                Toaster.success(
                                                    "Success",
                                                    "Copied to your clipboard"
                                                )
                                            }
                                        >
                                            <button className="ml-2 px-3 py-1 border border-gray-400  font-bold text-md text-gray-400">
                                                Copy URL
                                            </button>
                                        </CopyToClipboard>
                                        <div className="block mt-2 text-sm">
                                            <label className="flex gap-2 items-center mb-2">
                                                <input
                                                    type="checkbox"
                                                    checked={options.hideTitle}
                                                    onChange={(e) =>
                                                        setOptions({
                                                            ...options,
                                                            hideTitle: !options.hideTitle,
                                                        })
                                                    }
                                                    className="flex-shrink-0 w-5 h-5"
                                                />
                                                <span>Hide title & description</span>
                                            </label>
                                            <label className="flex gap-2 items-center mb-2">
                                                <input
                                                    type="checkbox"
                                                    checked={options.hideTools}
                                                    onChange={(e) =>
                                                        setOptions({
                                                            ...options,
                                                            hideTools: !options.hideTools,
                                                        })
                                                    }
                                                    className="flex-shrink-0 w-5 h-5"
                                                />
                                                <span>Hide buttons: Copy & Create image</span>
                                            </label>
                                            <label className="flex gap-2 items-center mb-2">
                                                <span className="flex-shrink-0">File:</span>
                                                <input
                                                    type="text"
                                                    checked={options.hideTools}
                                                    onChange={(e) =>
                                                        setOptions({
                                                            ...options,
                                                            focusFile: e.target.value.trim(),
                                                        })
                                                    }
                                                    placeholder="e.g. index.js / empty for all"
                                                    className="w-full block border border-gray-400 p-1 rounded outline-none"
                                                />
                                            </label>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <button
                                            className="px-3 py-1 border border-green-600 text-md text-green-600"
                                            onClick={createLink}
                                        >
                                            Create link
                                        </button>
                                    </>
                                )}
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <p>You can't edit this, because you are not the owner of this item.</p>
                    </>
                )}
            </div>
        </div>
    )
}
