import React, { useEffect, useState } from 'react'
import { IoMdRefresh } from 'react-icons/io';
import SLMan from '../../SLMan';

export const Profile = () => {

    const [vscodeToken, setVsCodeToken] = useState("");
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        SLMan.requestAPI('ext/snippetland/getapptoken?user=' + SLMan.UserData.userid + "&app=vscode", result => {
            if (result.error) {
                console.error(result.errorID, result.errorMessage);
            } else {
                var token = result.data.token;
                setVsCodeToken(token);
            }
        });
        SLMan.requestAPI('ext/snippetland/dashboard?user=' + SLMan.UserData.userid, result => {
            if (result.error) {
                console.error(result.error, result.errorMessage);
            } else {
                setUserData(result.data);
            }
        });
    }, []);

    const regenVSCode = () => {
        const ok = window.confirm("Are you sure? When you regenerate the token, the current token is immediatly invalid.")
        if (ok) {
            SLMan.requestAPI('ext/snippetland/recreatetoken?user=' + SLMan.UserData.userid + "&app=vscode", result => {
                if (result.error) {
                    console.error(result.errorID, result.errorMessage);
                } else {
                    var token = result.data.token;
                    setVsCodeToken(token);
                }
            });
        }
    }

    const highlightText = function (event) {
        event.currentTarget.select()
    }

    if (!userData) return (
        <></>
    )

    return (
        <div className="w-full max-w-4xl mx-auto grid grid-cols-1 sm:grid-cols-2 gap-6">

            <div className="mt-6 px-4 py-6 rounded-sm bg-white sm:col-span-2">
                <h1 className="font-bold text-3xl">Profile and Settings</h1>
            </div>

            <div className="my-3 px-4 py-6 rounded-sm bg-white">

                <h2 className="text-2xl font-bold mb-4">Your Account</h2>

                <div className="p-4 mb-6 lg:flex items-center bg-gray-100">
                    <div className="h-11 w-11 bg-center bg-cover rounded-full" style={{
                        backgroundImage: 'url(' + SLMan.UserData.image + ')'
                    }}></div>
                    <div className="ml-4">
                        <div className="font-bold">{SLMan.UserData.nickname}</div>
                        <div className="text-sm">{SLMan.UserData.email}</div>
                    </div>
                </div>

                <ul>
                    <li>
                        <span className="font-bold text-purple-600">Joined:</span> {userData.info.registered}
                    </li>
                    <li>
                        <span className="font-bold text-purple-600">Plan:</span> {userData.rank.display}
                    </li>
                    <li>
                        <span className="font-bold text-purple-600">Sharing:</span> {userData.rank.share ? "Enabled" : "Disabled"}
                    </li>
                    <li>
                        <span className="font-bold text-purple-600">Snippets / 30d:</span> {userData.rank.name == "plus" ? "Unlimited" : userData.rank.snippets} (currently {userData.used.snippets})
                    </li>
                </ul>

            </div>

            <div className="my-3 px-4 py-6 rounded-sm bg-white">

                <h2 className="text-2xl font-bold mb-4">Linked accounts</h2>

                <p className="my-2">
                    Your Account is permanently linked with your <img src="https://globalstore.tubitor.com/imgs/logo.svg" alt="Tubitor" title="Tubitor" className="inline-block h-4 -mt-1" /> Account. We use MyTubitor to log you in and
                    to get the following details:
                </p>

                <ul className="list-disc ml-4 my-2">
                    <li>Name</li>
                    <li>E-Mail</li>
                    <li>Profileimage</li>
                </ul>

                <p className="my-2">
                    To change any of these settings, please navigate to MyTubitor. If you want to delete your
                    SnippetLand account, please contact us (
                    <span className="text-purple-600">team@snippet.land</span>). If you want to delete your
                    Tubitor account, please contact Tubitor (
                    <span className="text-purple-600">support@tubitor.com</span>).
                </p>

                <a href="https://my.tubitor.com" className="font-bold text-purple-600" target="_blank">
                    Go to MyTubitor
                </a>

            </div>

            <div className="my-3 px-4 py-6 rounded-sm bg-white">

                <h2 className="text-2xl font-bold mb-4">Auth tokens</h2>

                <div className="p-4 bg-gray-100">
                    <div className="font-bold text-sm">
                        Visual Studio Code (Official Extension)
                    </div>
                    <div className="flex items-center">
                        <input type="text" placeholder="Token" readOnly={true} onClick={highlightText} value={vscodeToken}
                            className="p-1 rounded-none border border-red-600 text-xs flex-1 h-8" />
                        <button onClick={regenVSCode} className="p-1 rounded-none border text-white border-red-600 bg-red-600 text-md h-8 w-8 flex items-center justify-center">
                            <IoMdRefresh />
                        </button>
                    </div>
                </div>

            </div>

        </div>
    )
}
