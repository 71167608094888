import React from 'react'

export const Footer = () => {
    return (
        <div className="bg-gray-100 px-8 py-8">
            <div className="max-w-6xl mx-auto md:flex text-gray-600 justify-between">
                <div className="">
                    &copy; {new Date().getFullYear()} <a href="https://tubitor.com" rel="noreferrer" target="_blank">Tubitor</a>
                </div>
                <div className="">
                    <a href="mailto:team@snippet.land">Mail us</a> |&nbsp;
                    <a href="https://support.tubitor.com/knowledgebase.php?category=3" rel="noreferrer">Support</a> |&nbsp;
                    <a href="https://privacy.tubitor.com/imprint" rel="noreferrer">Imprint</a> |&nbsp;
                    <a href="https://privacy.tubitor.com/privacy" rel="noreferrer">Privacy policy</a>
                </div>
            </div>
        </div>
    )
}
