import { Route, Switch, useHistory } from "react-router";
import { Footer } from "./components/Footer";
import { Navigation } from "./components/Navigation";
import { Home } from "./pages/Home";
import { SnippetApp } from "./pages/App";
import { useEffect, useState } from "react";
import SLMan from './SLMan'
import { ShareView } from "./components/app/ShareView";

function App(props) {

  const [loading, setLoading] = useState(true);

  useEffect(() => {

    if (!SLMan.loggedin && localStorage.getItem('tsid') !== undefined) {
      SLMan.requestAPI('mytubitor/session?token=' + localStorage.getItem('tsid'), result => {
        if (result.error) {
          console.error(result.error, result.errorMessage);
          setLoading(false);
        } else {
          var userID = result.data.user;
          SLMan.requestAPI('mytubitor/user?id=' + userID, result2 => {
            if (result2.error) {
              console.error(result2.error, result2.errorMessage);
            } else {
              SLMan.UserData.nickname = result2.data.name;
              SLMan.UserData.username = result2.data.name;
              SLMan.UserData.email = result2.data.mail;
              SLMan.UserData.image = result2.data.profileimage;

              SLMan.requestAPI('ext/snippetland/login?tid=' + userID, resultLogin => {
                if (resultLogin.error) {
                  console.error(resultLogin.error, resultLogin.errorMessage);
                } else {
                  // SLMan.UserData.image = resultLogin.image;
                  SLMan.UserData.loggedin = true;
                  SLMan.UserData.userid = resultLogin.data.userid;
                }
                setLoading(false);
              });
            }
          });
        }
      });
    } else {
      setLoading(false);
    }

  }, [props]);

  if (loading)
    return <LoadingScreen />

  return (
    <div id="app" style={{ fontFamily: "inter" }} className="bg-gray-100 min-h-screen">
      <Switch>

        <Route path="/login" exact><LoginScreen /></Route>
        <Route path="/logout" exact><LogoutScreen /></Route>
        <Route path="/letmein" exact><LetMeInScreen /></Route>

        <Route path="/app">
          <SnippetApp />
        </Route>

        <Route path="/s/*">
          <ShareView />
        </Route>

        <Route path="/">
          <Navigation />
          <div id="inner">

            <Route path="/" exact={true} component={Home} />

          </div>
          <Footer />
        </Route>
      </Switch>
    </div>
  );
}

export default App;

const LoginScreen = (props) => {
  useEffect(() => window.location.href = "https://my.tubitor.com/login?service=snippetland", [])
  return <LoadingScreen />
}
const LogoutScreen = (props) => {
  useEffect(() => {
    localStorage.removeItem('tsid');
    window.location.href = "/";
  }, [])
  return <LoadingScreen />
}
const LetMeInScreen = (props) => {
  const [error, setError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();
  useEffect(() => {
    var GET = window.location.search;
    if (GET.startsWith('?tlc=')) {
      var logincode = window.location.search.replace('?tlc=', '');

      SLMan.requestAPI('mytubitor/checklogin?tlc=' + logincode, result => {
        if (result.error) {
          setErrorMessage(result.errorMessage);
          setError(result.errorID);
        } else {
          var userID = result.data.user;
          var session = result.data.req.session;
          localStorage.setItem('tsid', session);
          SLMan.requestAPI('mytubitor/user?id=' + userID, result2 => {
            if (result2.error) {
              setErrorMessage(result2.errorMessage + " 2");
              setError(result2.errorID + " 2");
            } else {
              SLMan.UserData.loggedin = true;
              SLMan.UserData.nickname = result2.data.name;
              SLMan.UserData.username = result2.data.name;
              SLMan.UserData.email = result2.data.mail;
              SLMan.UserData.image = result2.data.profileimage;
              // SLMan.UserData.userid = userID;

              SLMan.requestAPI('ext/snippetland/login?tid=' + userID, resultLogin => {
                if (resultLogin.error) {
                  console.error(resultLogin.error, resultLogin.errorMessage);
                } else {
                  // SLMan.UserData.image = resultLogin.image;
                  SLMan.UserData.userid = resultLogin.data.userid;
                }

                history.push('/app');
              });
            }
          });
        }
      });

    }
  }, [])

  if (error !== "") return <div>
    {errorMessage}
    <div className="id">{error}</div>
  </div>

  return (<LoadingScreen />)
}

export const LoadingScreen = (props) => {
  return (
    <div className="fixed top-0 left-0 h-screen w-screen flex justify-center items-center bg-white">
      <div className="max-w-lg">
        <div className="w-32 mx-auto mb-10 animate-spin">
          <img src="/icon.png" alt="SnippetLand" />
        </div>
        <div className="text-3xl font-extrabold text-center">
          {props.label || "We're on it..."}
        </div>
      </div>
    </div>
  )
}