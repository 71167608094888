import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { IoMdCash, IoMdCode, IoMdCube, IoMdHelpCircle, IoMdHome, IoMdMenu, IoMdStar } from "react-icons/io"
import SLMan from "../SLMan"

export const Navigation = () => {

    const [open, setOpen] = useState(false)

    return <>
        <header className="w-full sticky z-30 top-0 left-0">
            {/* <HeaderMessage color="green-500" text="white">
                🔥 The <b>new Visual Studio Code Extension</b> is out! <Link className="underline" to="/extension">Check it out here</Link>
            </HeaderMessage> */}
            <nav className="bg-white px-4 shadow-sm sticky top-0 z-50 left-0">
                <div className="py-4 max-w-6xl mx-auto flex justify-between align-middle flex-wrap lg:flex-nowrap">
                    <div className="flex items-center">
                        <Link to="/">
                            <Logo />
                        </Link>
                    </div>
                    <button onClick={e => setOpen(!open)} className="lg:hidden rounded-lg bg-gray-100 px-3 py-3 text-3xl outline-none">
                        <IoMdMenu />
                    </button>
                    <div className={(!open ? "hidden" : "") + " w-full pt-4 divide-y-2 divide-gray-100 lg:divide-y-0 lg:pt-0 lg:flex lg:justify-end lg:align-middle lg:w-auto"}>
                        <NavItem to="#start" icon={<IoMdHome />} exact={true}>
                            Home
                        </NavItem>
                        <NavItem to="#features" icon={<IoMdStar />}>
                            Features
                        </NavItem>
                        <NavItem to="#extension" icon={<IoMdCode />}>
                            Extension
                        </NavItem>
                        <NavItem to="#pricing" icon={<IoMdCash />}>
                            Pricing
                        </NavItem>
                        <NavButton to="/app" icon={<IoMdCube />}>
                            {SLMan.UserData.loggedin ? "App" : "Get started"}
                        </NavButton>
                    </div>
                </div>
            </nav>
        </header>
    </>
}

const NavItem = ({ to, color, textColor, children, icon, exact }) => {
    return (
        <a href={to}
            className={`flex lg:inline-flex transition-all rounded-lg bg-transparent px-3 py-3 text-gray-700 hover:text-purple-500 hover:bg-purple-50 items-center ml-2 outline-none focus:bg-purple-50`}>
            <span className={`text-xl pr-1 text-purple-500`}>{icon}</span> <span>{children}</span>
        </a>
    )
}

const NavButton = ({ to, color, children, icon }) => {
    return (
        <Link to={to}
            className={`flex lg:inline-flex transition-all rounded-lg px-3 py-3 text-white bg-purple-500 items-center ml-2 outline-none focus:bg-purple-800 hover:bg-purple-700`}>
            <span className={`text-xl pr-1`}>{icon}</span> <span>{children}</span>
        </Link>
    )
}

const Logo = () => {
    return (
        <img className="h-14" src="/logo.svg" alt="SnippetLand" loading="lazy" />
    )
}

// const HeaderMessage = ({ color, text, children }) => {
//     return (
//         <div className={`bg-${color} text-${text} px-4 py-1`}>
//             <div className="max-w-6xl mx-auto">
//                 {children}
//             </div>
//         </div>
//     )
// }