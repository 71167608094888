import React, { useEffect, useRef, useState } from "react"
import CopyToClipboard from "react-copy-to-clipboard"
import {
    FaAlignLeft,
    FaCode,
    FaCoffee,
    FaCogs,
    FaCss3Alt,
    FaDocker,
    FaFileCode,
    FaGithub,
    FaHtml5,
    FaImage,
    FaJava,
    FaJs,
    FaMarkdown,
    FaPencilAlt,
    FaPhp,
    FaPlus,
    FaPython,
    FaReact,
    FaSass,
} from "react-icons/fa"
import { IoMdCheckmark, IoMdClose, IoMdTrash } from "react-icons/io"
import ReactMarkdown from "react-markdown"
import { Prompt } from "react-router"
import SLMan from "../../SLMan"
import Toaster from "../Toaster"
import SnippetAceEditor from "./SnippetAceEditor"
const gfm = require("remark-gfm")

export const SnippetEditor = (props) => {
    const hideTitle = props.hideTitle === true ? true : false
    const hideTools = props.hideTools === true ? true : false
    const focusFile = props.focusFile || null

    const [title, setTitle] = useState("")
    const [desc, setDesc] = useState("")

    const [loading, setLoading] = useState(true)
    const [saveNextTime, setSaveNextTime] = useState(false)

    const [snippetID, setSnippetID] = useState("")

    const [files, setFiles] = useState({})
    const [fileContent, setFileContent] = useState({})
    const [selectedFile, setSelectedFile] = useState("")

    const [markdownView, setMarkdownView] = useState(true)

    const [newFileMenuOpen, setNewFileMenuOpen] = useState(false)
    const [newFileName, setNewFileName] = useState("")
    const newFileElement = useRef(null)

    const [editFileMenuOpen, setEditFileMenuOpen] = useState(false)
    const [editFileName, setEditFileName] = useState("")
    const [editingFile, setEditingFile] = useState("")
    const editFileElement = useRef(null)

    const [error, setError] = useState("")

    const [deleteConfirm, setDeleteConfirm] = useState(false)
    const [deleted, setDeleted] = useState(false)

    const [isFileDragging, setIsFileDragging] = useState(false)

    const [unsaved, setUnsaved] = useState(false)

    useEffect(() => {
        if (props.id != snippetID) {
            // New snippet
            setLoading(true)
            setTitle("")
            setDesc("")
            setSnippetID(props.id)
            setDeleteConfirm(false)
            setDeleted(false)
            setUnsaved(false)
            SLMan.requestAPI(
                "ext/snippetland/content?" +
                    props.contentType +
                    "=" +
                    props.id +
                    "&user=" +
                    SLMan.UserData.userid,
                (result) => {
                    setSelectedFile("")
                    if (result.error) {
                        console.error(result.error, result.errorMessage)
                        setError(result.errorMessage)
                    } else {
                        setTitle(result.data.title)
                        setDesc(result.data.subtitle)
                        var content = result.data.content
                        var _editFilenames = {}
                        var _newFiles = {}
                        var _editingFilenames = {}
                        Object.keys(content).map((item) => {
                            _editFilenames[item] = item
                            _newFiles[item] = { mode: getEditorModeByExtension(item) }
                            _editingFilenames[item] = false
                        })
                        setFileContent(content)
                        setFiles(_newFiles)

                        Object.keys(_newFiles).forEach((item, index) => {
                            if (
                                item.toLowerCase() === "_screen.png" ||
                                item.toLowerCase() === "_screen.jpg" ||
                                item.toLowerCase() === "_screen.jpeg"
                            ) {
                                setSelectedFile(item)
                            }
                        })

                        Object.keys(_newFiles).forEach((item, index) => {
                            if (item.toLowerCase() === "readme.md") {
                                setSelectedFile(item)
                                setMarkdownView(true)
                            }
                        })

                        if (focusFile) {
                            Object.keys(_newFiles).forEach((item, index) => {
                                if (item.toLowerCase() === focusFile) {
                                    setSelectedFile(item)
                                }
                            })
                        }

                        setLoading(false)
                    }
                }
            )
        }
    }, [props])

    useEffect(() => {
        if (!props.readOnly) {
            window.ondragenter = (event) => {
                if (event.dataTransfer.types.includes("Files")) setIsFileDragging(true)
            }
            window.ondragexit = (event) => {
                setIsFileDragging(false)
            }
            window.ondragend = (event) => {
                setIsFileDragging(false)
            }
            window.ondragleave = (event) => {
                if (!event.clientX && !event.clientY) setIsFileDragging(false)
            }
            return () => {
                window.ondragenter = null
                window.ondragexit = null
                window.ondragend = null
                window.ondragleave = null
            }
        }
    }, [])

    const save = () => {
        let stitle = title.split()
        if (title.length > 50 || title.length < 3) {
            Toaster.error("Failed", "Title must be 3 - 50 chars long!")
            return
        }
        let sdesc = desc.split()
        if (sdesc.length > 120) {
            Toaster.error("Failed", "Description is too long!")
            return
        }
        var data = new FormData()
        var rawContent = JSON.stringify(fileContent)
        data.append("content", rawContent)
        fetch(
            "https://v2.tubitorapis.com/ext/snippetland/" +
                props.saveAPI +
                "?snippet=" +
                props.id +
                "&user=" +
                SLMan.UserData.userid +
                "&title=" +
                encodeURIComponent(stitle) +
                "&subtitle=" +
                encodeURIComponent(desc),
            {
                method: "POST",
                body: data,
            }
        )
            .then((data) => data.json())
            .then((json) => {
                if (props.onNameChange) props.onNameChange(title, desc)
                setUnsaved(false)
                // setSaving(false);
                // setUnsaved(false);
            })
    }

    const openNewFileMenu = () => {
        setNewFileName("")
        setNewFileMenuOpen(true)
        setTimeout(() => {
            newFileElement.current.focus()
        }, 100)
    }

    const openEditFileMenu = (filename) => {
        setEditingFile(filename)
        setEditFileName(filename)
        setEditFileMenuOpen(true)
        setTimeout(() => {
            editFileElement.current.focus()
            editFileElement.current.select()
        }, 100)
    }

    const createNewFile = (name, defaultContent = "") => {
        name = name.trim()
        if (name.length > 0) {
            setFileContent({
                ...fileContent,
                [name]: defaultContent,
            })
            setFiles({
                ...files,
                [name]: { mode: getEditorModeByExtension(name) },
            })
            setNewFileMenuOpen(false)
            setNewFileName("")
            setSelectedFile(name)
            setUnsaved(true)
        }
    }

    const renameFile = (oldName, newName) => {
        newName = newName.trim()
        if (newName.length > 0) {
            if (Object.keys(files).includes(oldName) && !Object.keys(files).includes(newName)) {
                var newFiles = {
                    ...files,
                    [newName]: { ...files[newName], mode: getEditorModeByExtension(newName) },
                }
                var newFilecontent = {
                    ...fileContent,
                    [newName]: fileContent[oldName],
                }
                if (selectedFile == oldName) setSelectedFile(newName)
                delete newFiles[oldName]
                delete newFilecontent[oldName]
                setFiles(newFiles)
                setFileContent(newFilecontent)
                setUnsaved(true)
            }
            setEditFileMenuOpen(false)
            setEditFileName("")
        }
    }

    const deleteFile = (filename) => {
        if (Object.keys(files).includes(filename)) {
            if (selectedFile == filename) setSelectedFile("")
            delete files[filename]
            delete fileContent[filename]
            setFiles(files)
            setFileContent(fileContent)
            setUnsaved(true)
        }
        setEditFileMenuOpen(false)
        setEditFileName("")
    }

    const changeSnippet = (name, newVal) => {
        setFileContent({
            ...fileContent,
            [name]: newVal,
        })
        setUnsaved(true)
    }

    const deleteSnippet = () => {
        SLMan.requestAPI(
            "ext/snippetland/deletefile?user=" + SLMan.UserData.userid + "&id=" + props.id,
            (result) => {
                if (result.error) {
                    console.error(result.error, result.errorMessage)
                    Toaster.error("Failed", "No permissions, is this your snippet?")
                } else {
                    setDeleted(true)
                    if (props.onDelete) props.onDelete(props.id)
                }
            }
        )
    }

    const onFileUpload = (event) => {
        if (!props.readOnly) {
            const done = () => {
                jobsRunning--
                if (jobsRunning === 0) {
                    var _newFiles = files
                    var _newContent = fileContent
                    if (_newContent.length === 0) _newContent = {}
                    Object.keys(newFiles).map((item) => {
                        _newFiles[item] = { mode: getEditorModeByExtension(item) }
                        _newContent[item] = newFiles[item]
                    })
                    setFileContent(_newContent)
                    setFiles(_newFiles)
                    setLoading(false)
                    setUnsaved(true)
                }
            }

            setLoading(true)
            setIsFileDragging(false)
            var ufiles = event.target.files
            var jobsRunning = ufiles.length
            var newFiles = {}
            Object.keys(ufiles).map((index) => {
                var filename = ufiles[index].name
                var mode = getEditorModeByExtension(filename)
                var extension = filename
                if (extension.includes("."))
                    extension = filename.split(".")[filename.split(".").length - 1]
                extension = extension.toLowerCase()
                if (extension === "jpg" || extension === "png" || extension === "jpeg") {
                    if (!Object.keys(files).includes(filename)) {
                        var file = ufiles[index]
                        var formData = new FormData()
                        formData.append("file", file)
                        fetch("https://usercontent-images.snippet.land/handler.php", {
                            method: "POST",
                            body: formData,
                        })
                            .then((result) => result.json())
                            .then((json) => {
                                if (json.error === false) {
                                    var filecontent = json.url
                                    newFiles[filename] = filecontent
                                }
                                done()
                            })
                            .catch((error) => {
                                console.error(error)
                                done()
                            })
                    }
                } else if (
                    (mode === "plain_text" && extension === "txt") ||
                    mode !== "plain_text"
                ) {
                    if (!Object.keys(files).includes(filename)) {
                        var reader = new FileReader()
                        reader.readAsText(ufiles[index])
                        reader.onload = () => {
                            var filecontent = reader.result
                            newFiles[filename] = filecontent
                            done()
                        }
                    } else {
                        done()
                    }
                } else {
                    done()
                }
            })
        }
    }

    if (saveNextTime) {
        setSaveNextTime(false)
        save()
    }

    if (loading && error)
        return (
            <div className="h-full w-full flex justify-center items-center">
                <span className="font-extrabold text-3xl opacity-40">{error}</span>
            </div>
        )

    if (loading)
        return (
            <div className="h-full w-full flex justify-center items-center">
                <span className="font-extrabold text-3xl opacity-40">Preparing...</span>
            </div>
        )

    if (deleted)
        return (
            <div className="h-full w-full flex justify-center items-center">
                <span className="font-extrabold text-3xl opacity-40">Deleted.</span>
            </div>
        )

    let isMarkdownFile,
        isMarkdownView,
        isImage,
        showCodebox = false

    if (selectedFile) {
        isMarkdownFile = files[selectedFile].mode === "markdown"
        isMarkdownView = isMarkdownFile && markdownView
        isImage =
            getExtension(selectedFile) === "png" ||
            getExtension(selectedFile) === "jpg" ||
            getExtension(selectedFile) === "jpeg"
        showCodebox = !isMarkdownView && !isImage
    }

    return (
        <div className="h-full w-full flex flex-col relative">
            <Prompt
                when={unsaved}
                message={(location) => `You have unsaved changes! Are you sure you want to leave?`}
            />

            {/** FILE UPLOAD */}
            {!props.readOnly && (
                <div
                    className={
                        (isFileDragging ? "flex" : "hidden") +
                        " p-14 z-40 fixed top-0 left-0 h-screen w-screen bg-black bg-opacity-40 justify-center items-center"
                    }
                >
                    <label
                        htmlFor="snippet-editor-fileupload"
                        className="relative h-full w-full bg-white shadow-md flex justify-center items-center text-6xl font-extrabold text-center hover:text-purple-600"
                    >
                        Drag files here to
                        <br />
                        add to the Snippet
                        <input
                            type="file"
                            className="absolute top-0 left-0 opacity-0 text-lg h-full w-full"
                            id="snippet-editor-fileupload"
                            multiple
                            onChange={onFileUpload}
                        />
                    </label>
                </div>
            )}

            {/** NEW FILE */}
            <div
                className={
                    "fixed top-0 left-0 h-full w-full bg-black bg-opacity-30 flex-col items-center justify-center z-20 " +
                    (newFileMenuOpen ? "flex" : "hidden")
                }
            >
                <div className="rounded bg-white shadow-md p-4 w-80 relative">
                    <button
                        onClick={(e) => setNewFileMenuOpen(false)}
                        className="absolute -right-11 top-0 rounded-full w-7 h-7 bg-white shadow-md flex justify-center items-center"
                    >
                        <IoMdClose />
                    </button>
                    <h2 className="font-extrabold text-2xl">Create new file</h2>
                    <input
                        ref={newFileElement}
                        onKeyDown={(e) => {
                            if (e.key.toLowerCase() == "enter") createNewFile(newFileName)
                            else if (e.key.toLowerCase() == "escape") setNewFileMenuOpen(false)
                        }}
                        type="text"
                        value={newFileName}
                        placeholder="Filename"
                        onChange={(e) => setNewFileName(e.target.value)}
                        className="px-3 py-1 border border-gray-200 text-md mt-4 w-full"
                    />
                    <button
                        className="px-3 py-1 border border-purple-400 text-md mt-4 w-full"
                        onClick={(e) => createNewFile(newFileName)}
                    >
                        Create file
                    </button>
                </div>
            </div>

            {/** EDIT FILE */}
            <div
                className={
                    "fixed top-0 left-0 h-full w-full bg-black bg-opacity-30 flex-col items-center justify-center z-20 " +
                    (editFileMenuOpen ? "flex" : "hidden")
                }
            >
                <div className="rounded bg-white shadow-md p-4 w-80 relative">
                    <button
                        onClick={(e) => setEditFileMenuOpen(false)}
                        className="absolute -right-11 top-0 rounded-full w-7 h-7 bg-white shadow-md flex justify-center items-center"
                    >
                        <IoMdClose />
                    </button>
                    <h2 className="font-extrabold text-2xl">Create new file</h2>
                    <input
                        ref={editFileElement}
                        onKeyDown={(e) => {
                            if (e.key.toLowerCase() == "enter")
                                renameFile(editingFile, editFileName)
                            else if (e.key.toLowerCase() == "escape") setEditFileMenuOpen(false)
                        }}
                        type="text"
                        value={editFileName}
                        placeholder="Filename"
                        onChange={(e) => setEditFileName(e.target.value)}
                        className="px-3 py-1 border border-gray-200 text-md mt-4 w-full"
                    />
                    <button
                        className="px-3 py-1 border border-purple-400 text-md mt-4 w-full"
                        onClick={(e) => renameFile(editingFile, editFileName)}
                    >
                        Rename file
                    </button>
                    <button
                        className="px-3 py-1 border border-red-400 text-md mt-4 w-full"
                        onClick={(e) => deleteFile(editingFile)}
                    >
                        Delete file
                    </button>
                </div>
            </div>

            {!hideTitle && (
                <div className="flex justify-between items-top">
                    <div className="flex-1">
                        {props.readOnly ? (
                            <>
                                <input
                                    type="text"
                                    value={title}
                                    readOnly={true}
                                    placeholder="Snippet name required"
                                    className="w-full rounded-0 bg-transparent block px-1 py-1 text-3xl font-extrabold outline-none"
                                />
                                <input
                                    type="text"
                                    value={desc}
                                    readOnly={true}
                                    placeholder="Snippet description"
                                    className="w-full rounded-0 bg-transparent block px-1 py-0.5 text-md font-bold outline-none"
                                />
                            </>
                        ) : (
                            <>
                                <input
                                    type="text"
                                    value={title}
                                    onChange={(e) => {
                                        setTitle(e.target.value)
                                        setUnsaved(true)
                                    }}
                                    placeholder="Snippet name required"
                                    className="w-full rounded-0 bg-transparent block px-1 py-1 text-3xl font-extrabold outline-none"
                                />
                                <input
                                    type="text"
                                    value={desc}
                                    onChange={(e) => {
                                        setDesc(e.target.value)
                                        setUnsaved(true)
                                    }}
                                    placeholder="Snippet description"
                                    className="w-full rounded-0 bg-transparent block px-1 py-0.5 text-md font-bold outline-none"
                                />
                            </>
                        )}
                    </div>
                    {!props.readOnly && (
                        <div className="flex">
                            {unsaved && (
                                <button
                                    className="bg-purple-600 py-2 h-12 px-4 text-white font-bold rounded-md"
                                    onClick={(e) => setSaveNextTime(true)}
                                >
                                    Save
                                </button>
                            )}
                            {!deleteConfirm ? (
                                <button
                                    className="bg-red-600 ml-2 py-2 h-12 px-4 text-white font-bold rounded-md"
                                    onClick={(e) => setDeleteConfirm(true)}
                                >
                                    <IoMdTrash />
                                </button>
                            ) : (
                                <button
                                    className="bg-red-700 ml-2 py-2 h-12 px-4 text-white font-bold rounded-md"
                                    onClick={deleteSnippet}
                                >
                                    <IoMdCheckmark />
                                </button>
                            )}
                        </div>
                    )}
                </div>
            )}

            <div className={`h-full w-full flex flex-col ${hideTitle ? "" : "mt-4"}`}>
                {!(focusFile && selectedFile) && (
                    <div className="flex flex-wrap items-stretch bg-white rounded-sm">
                        {Object.keys(files)
                            .sort()
                            .map((file, index) => (
                                <div
                                    key={index}
                                    className={
                                        "group flex items-center border-t-2 py-2 px-6 pr-1 hover:bg-gray-50 cursor-pointer" +
                                        (selectedFile == file ? " border-purple-600" : "")
                                    }
                                    onClick={(e) => setSelectedFile(file)}
                                >
                                    <div className="pr-2">
                                        {getEditoriconByMode(files[file].mode)}
                                    </div>
                                    <div className="font-bold flex items-center">
                                        {file}
                                        {!props.readOnly && (
                                            <div
                                                className="opacity-0 text-sm group-hover:opacity-60 pl-2"
                                                onClick={(e) => openEditFileMenu(file)}
                                            >
                                                <FaPencilAlt />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        {!props.readOnly && (
                            <div
                                className="flex items-center border-t-2 py-2 px-6 hover:bg-gray-50 cursor-pointer"
                                onClick={(e) => openNewFileMenu()}
                            >
                                <div>
                                    <FaPlus />
                                </div>
                            </div>
                        )}
                    </div>
                )}
                <div className="h-full w-full mt-2 bg-white rounded-sm relative overflow-hidden">
                    {selectedFile && (
                        <>
                            {showCodebox && !hideTools && (
                                <div className="absolute z-10 top-2 right-4 bg-green-100 flex items-stretch gap-2">
                                    <CopyToClipboard
                                        text={fileContent[selectedFile]}
                                        onCopy={(e) =>
                                            Toaster.success(
                                                "The content is now in your clipboard!",
                                                "Copied!"
                                            )
                                        }
                                    >
                                        <div className="p-1.5 bg-green-600 text-sm rounded-sm shadow-sm select-none cursor-pointer text-white">
                                            Copy
                                        </div>
                                    </CopyToClipboard>
                                    <button
                                        onClick={() => {
                                            let mode = files[selectedFile].mode
                                            if (mode == "html") mode = "xml"
                                            let title = selectedFile
                                            let code = encodeURIComponent(
                                                btoa(fileContent[selectedFile])
                                            )
                                            let link = `https://ray.so/?colors=breeze&background=true&darkMode=true&padding64&title=${title}&code=${code}&language=${mode}`
                                            window.open(link, "_blank").focus()
                                        }}
                                        className="py-1.5 px-2 bg-blue-600 text-sm rounded-sm shadow-sm select-none cursor-pointer text-white"
                                    >
                                        <FaImage />
                                    </button>
                                </div>
                            )}

                            {isMarkdownFile && (
                                <div
                                    className="absolute z-10 bottom-2 right-4 p-1.5 bg-purple-100 text-sm rounded-sm shadow-sm select-none cursor-pointer"
                                    onClick={(e) => setMarkdownView(!markdownView)}
                                >
                                    Toggle markdown view
                                </div>
                            )}

                            {isMarkdownView && (
                                <div className="h-full w-full overflow-hidden absolute">
                                    <div className="markdown-container h-full w-full overflow-y-auto p-4">
                                        <ReactMarkdown plugins={[gfm]}>
                                            {fileContent[selectedFile]}
                                        </ReactMarkdown>
                                    </div>
                                </div>
                            )}

                            {isImage && (
                                <div
                                    className="h-full w-full bg-contain bg-no-repeat bg-center"
                                    style={{
                                        backgroundImage: "url(" + fileContent[selectedFile] + ")",
                                    }}
                                ></div>
                            )}

                            {showCodebox && (
                                <SnippetAceEditor
                                    name={selectedFile}
                                    mode={files[selectedFile].mode}
                                    value={fileContent[selectedFile]}
                                    onlyView={props.readOnly || false}
                                    saveFunc={(e) => setSaveNextTime(true)}
                                    changeFunc={(...data) => {
                                        if (!props.readOnly) changeSnippet(...data)
                                    }}
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

function getExtension(filename) {
    var extension = filename
    if (extension.includes(".")) {
        extension = filename.split(".")[filename.split(".").length - 1]
    }
    extension = extension.toLowerCase()
    return extension
}

function getEditorModeByExtension(filename) {
    var extension = filename
    if (extension.includes(".")) {
        extension = filename.split(".")[filename.split(".").length - 1]
    }
    extension = extension.toLowerCase()
    switch (extension) {
        case "java":
            return "java"
        case "js":
            return "javascript"
        case "php":
            return "php"
        case "html":
            return "html"
        case "htm":
            return "html"
        case "jsx":
            return "jsx"
        case "css":
            return "css"
        case "scss":
            return "scss"
        case "py":
            return "python"
        case "ruby":
            return "ruby"
        case "rb":
            return "ruby"
        case "coffee":
            return "coffee"
        case "cs":
            return "csharp"
        case "conf":
            return "apache_conf"
        case "dockerfile":
            return "dockerfile"
        case "gitignore":
            return "gitignore"
        case "json":
            return "json"
        case "kts":
            return "kotlin"
        case "kt":
            return "kotlin"
        case "ktm":
            return "kotlin"
        case "sql":
            return "mysql"
        case "svg":
            return "svg"
        case "ts":
            return "typescript"
        case "vbs":
            return "vbscript"
        case "xml":
            return "xml"
        case "md":
            return "markdown"
    }
    return "plain_text"
}

function getEditoriconByMode(mode) {
    var icon = <FaFileCode />
    if (mode === "java") icon = <FaJava />
    if (mode === "javascript") icon = <FaJs />
    if (mode === "php") icon = <FaPhp />
    if (mode === "html") icon = <FaHtml5 />
    if (mode === "jsx") icon = <FaReact />
    if (mode === "css") icon = <FaCss3Alt />
    if (mode === "scss") icon = <FaSass />
    if (mode === "python") icon = <FaPython />
    if (mode === "coffee") icon = <FaCoffee />
    if (mode === "apache_conf") icon = <FaCogs />
    if (mode === "dockerfile") icon = <FaDocker />
    if (mode === "gitignore") icon = <FaGithub />
    if (mode === "json") icon = <FaAlignLeft />
    if (mode === "svg") icon = <FaImage />
    if (mode === "typescript") icon = <FaJs />
    if (mode === "xml") icon = <FaCode />
    if (mode === "markdown") icon = <FaMarkdown />
    return icon
}
