import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-php";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-jsx";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/mode-scss";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-ruby";
import "ace-builds/src-noconflict/mode-coffee";
import "ace-builds/src-noconflict/mode-csharp";
import "ace-builds/src-noconflict/mode-apache_conf";
import "ace-builds/src-noconflict/mode-nginx";
import "ace-builds/src-noconflict/mode-dockerfile";
import "ace-builds/src-noconflict/mode-gitignore";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-kotlin";
import "ace-builds/src-noconflict/mode-mysql";
import "ace-builds/src-noconflict/mode-perl";
import "ace-builds/src-noconflict/mode-plain_text";
import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/mode-svg";
import "ace-builds/src-noconflict/mode-typescript";
import "ace-builds/src-noconflict/mode-vbscript";
import "ace-builds/src-noconflict/mode-xml";
import "ace-builds/src-noconflict/mode-markdown";
import "ace-builds/src-noconflict/theme-textmate";

function SnippetAceEditor(props) {

    const updateValue = (val) => {
        if (props.changeFunc) {
            props.changeFunc(props.name, val);
        }
    }

    return <>

        <AceEditor
            placeholder=""
            mode={props.mode || 'plain_text'}
            theme="textmate"
            value={props.value}
            name={props.name}
            height="100%"
            width="100%"
            fontSize={18}
            showGutter={true}
            highlightActiveLine={true}
            setOptions={{
                useWorker: false,
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
                showPrintMargin: false,
                tabSize: 3,
            }}
            commands={[
                {
                    name: 'save',
                    bindKey: { win: "Ctrl-S", "mac": "Cmd-S" },
                    exec: function (editor) {
                        if (props.saveFunc) {
                            props.saveFunc();
                        }
                    }
                }
            ]}
            onChange={updateValue}
            readOnly={props.onlyView}
        />

    </>

}

export default SnippetAceEditor;