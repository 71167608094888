import React from 'react'
import { IoMdCode, IoMdCodeWorking, IoMdFolder, IoMdLogOut, IoMdPerson, IoMdShare } from 'react-icons/io'
import { Link, NavLink } from 'react-router-dom'

export const Wrapper = (props) => {
    return (
        <div className="min-h-screen w-full flex flex-wrap">

            <NavBar>
                <NavBarItem to="/app" exact={true}>
                    <IoMdCode />
                </NavBarItem>
                <NavBarItem to="/app/profile">
                    <IoMdPerson />
                </NavBarItem>
                <NavBarItem to="/logout" className="mt-auto">
                    <IoMdLogOut />
                </NavBarItem>
            </NavBar>

            <div className="w-screen h-screen pt-16 lg:pt-0 lg:pl-16 block">
                {props.children}
            </div>

        </div>
    )
}

const NavBar = (props) => {
    return (
        <div className="h-16 w-screen lg:h-screen lg:w-16 bg-white shadow-inner border-r border-gray-100 fixed top-0 left-0">
            <div className="h-full w-full flex justify-between lg:flex-col lg:justify-start">
                <div className="h-full w-16 lg:h-16 lg:w-full hover:bg-gray-100">
                    <Link to="/" className="block p-2">
                        <img className="h-12 w-12 object-contain" src="/icon.png" alt="SnippetLand" />
                    </Link>
                </div>
                <div className="flex lg:flex-col h-full">
                    {props.children}
                </div>
            </div>
        </div>
    )
}

const NavBarItem = (props) => {
    return (
        <NavLink to={props.to} exact={props.exact ?? false}
            className={`h-full w-16 lg:h-16 lg:w-full flex justify-center items-center text-xl hover:bg-gray-100 ${props?.className ?? ""}`}
            activeClassName="border-t lg:border-0 lg:border-l border-purple-500">
            {props.children}
        </NavLink>
    )
}
