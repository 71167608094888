import React, { useEffect, useState } from "react"
import { useParams } from "react-router"
import { SnippetEditor } from "./SnippetEditor"

export const ShareView = () => {
    const props = useParams()
    const [snippetID, setSnippetID] = useState("")

    const [options, setOptions] = useState({
        hideTitle: false,
        focusFile: null,
        hideTools: false,
    })

    useEffect(() => {
        const search = window.location.search.substring(1)
        if (search.length > 0) {
            const splitted = search.split("&")
            const params = {}
            splitted.forEach((s) => {
                params[s.split("=")[0]] = s.split("=")[1] ?? true
            })
            setOptions({
                ...options,
                hideTitle: params.notitle ? true : false,
                focusFile: params.file || null,
                hideTools: params.hidetools ? true : false,
            })
        }
    }, [])

    useEffect(() => {
        const sid = props[0]
        setSnippetID(sid)
    }, [props])

    return (
        <>
            <div className="h-screen w-full pt-4 pb-7 px-3 relative">
                {snippetID && (
                    <SnippetEditor
                        id={snippetID}
                        contentType="link"
                        readOnly={true}
                        saveAPI="save"
                        hideTools={options.hideTools}
                        hideTitle={options.hideTitle}
                        focusFile={options.focusFile}
                    />
                )}
                <a
                    href="https://tubi.to/snippetland-embed"
                    target="_blank"
                    className="absolute bottom-0 right-2 px-2 py-1 backdrop-blur-md bg-white bg-opacity-20 rounded-md hover:bg-opacity-50 z-50"
                >
                    <span className="text-gray-400 text-xs font-bold">Powered by snippet.land</span>
                </a>
            </div>
        </>
    )
}
