const SLMan = {

    UserData: {
        username: "loading",
        nickname: "Loading",
        email: "lo@di.ng",
        rank: 0,
        loggedin: false,
        image: '',
        userid: 'err',
    },

    requestAPI: function (url, callback) {
        fetch("https://v2.tubitorapis.com/" + url).then(data => data.json()).then(json => {
            if (json.meta.code === 200) {
                callback({
                    ...json,
                    error: false,
                });
            } else {
                callback({
                    error: true,
                    errorID: json.meta.message,
                    errorMessage: json.meta.output,
                });
            }
        }).catch(error => {
            callback({
                error: true,
                errorMessage: 'Can\'t reach API Server',
                jsError: error
            });
        });
    }

}

export default SLMan;