import React, { useEffect, useState } from 'react'
import { Route, Switch, useHistory } from 'react-router'
import { LoadingScreen } from '../App'
import { Browser } from '../components/app/Browser'
import { Profile } from '../components/app/Profile'
import { Wrapper } from '../components/app/Wrapper'
import SLMan from '../SLMan'

export const SnippetApp = () => {

    const [loading, setLoading] = useState(true)
    const history = useHistory();

    useEffect(() => {
        if (SLMan.UserData.loggedin) {
            setLoading(false)
        } else {
            history.push('/login')
        }
    }, [])

    if (loading) return <LoadingScreen />

    return (
        <div>

            <Wrapper>

                <Switch>

                    <Route path="/app/profile">
                        <Profile />
                    </Route>

                    <Route path="*">
                        <Browser />
                    </Route>

                </Switch>

            </Wrapper>

        </div>
    )
}
