import React from 'react'
import { IoMdCheckmarkCircleOutline, IoMdCloseCircleOutline } from 'react-icons/io'
import { Link } from 'react-router-dom'

export const Home = () => {
    return <>
        <div className="pt-28 px-8" id="start">

            <h1 className="text-center text-4xl md:text-6xl font-black">
                Share Code Snippets<br />with your Team
            </h1>

            <p className="mt-6 text-center text-gray-600 text-lg md:text-xl">
                Save your Code Snippets in the Cloud and access it everywhere.
            </p>

            <div className="mt-10 flex justify-center">
                <a href="#pricing" className="transition-all inline-block mx-auto px-8 py-5 text-xl rounded-md bg-purple-600 text-white hover:bg-purple-700 shadow-md hover:shadow-xl">
                    Get started
                </a>
            </div>

            <div className="mt-28 max-w-6xl mx-auto bg-white rounded-md shadow-sm p-3 md:flex justify-between text-center md:text-left">
                <a href="#features" className="block transition-all p-4 flex-1 mr-3 rounded-lg">
                    <span className="block uppercase text-gray-400 text-sm">for developers</span>
                    <span className="block mt-4 mb-2 text-2xl font-extrabold">The Cloud for your Code Snippets</span>
                </a>
                <a href="#extension" className="block transition-all p-4 flex-1 mr-3 rounded-lg">
                    <span className="block uppercase text-gray-400 text-sm">directly integrated</span>
                    <span className="block mt-4 mb-2 text-2xl font-extrabold">Extension for Visual Studio Code</span>
                </a>
                <a href="#pricing" className="block transition-all p-4 flex-1 rounded-lg">
                    <span className="block uppercase text-gray-400 text-sm">free to use</span>
                    <span className="block mt-4 mb-2 text-2xl font-extrabold">Login and get started</span>
                </a>
            </div>

        </div>
        <div className="mt-20 px-8 py-20 bg-white" id="features">

            <h2 className="text-center font-extrabold text-3xl md:text-5xl">Features</h2>

            <div className="mt-24 flex justify-center flex-wrap md:flex-nowrap max-w-6xl mx-auto">
                <div className="pt-10 md:pt-0 md:pr-8 md:flex-1 order-2 md:order-1 w-full md:w-auto">
                    <span className="block uppercase text-gray-400 text-sm">The SnippetLand editor</span>
                    <h3 className="text-2xl md:text-4xl pt-4 font-extrabold">Intuitive Editor</h3>
                    <p className="pt-8 text-gray-600 text-xl">
                        Create a Snippet with multiple files for multiple languages.
                        <br /><br />
                        The Code Editor supports syntax highlighting, formatting a modern design.
                        You can use the editor for writing, viewing and editing your Snippets.
                    </p>
                </div>
                <div className="relative md:flex-1 order-1 md:order-2 w-full md:w-auto">
                    <div className="rounded bg-purple-400 absolute transform -rotate-3 z-0 w-full h-full top-0 left-0"></div>
                    <img src="/images/screen_editor.jpeg" alt="SnippetLand Editor" loading="lazy" className="relative w-full shadow-sm border border-purple-300" />
                </div>
            </div>

            <div className="mt-24 flex justify-center flex-wrap md:flex-nowrap max-w-6xl mx-auto">
                <div className="pt-10 md:pt-0 md:pl-8 md:flex-1 order-2 md:order-2 w-full md:w-auto">
                    <span className="block uppercase text-gray-400 text-sm">Special Features</span>
                    <h3 className="text-2xl md:text-4xl pt-4 font-extrabold">Documentation and Information</h3>
                    <p className="pt-8 text-gray-600 text-xl">
                        You can use SnippetLand for more than just saving the raw code, you can also use it to explain
                        and document the Snippet.
                        <br /><br />
                        The Editor supports Markdown and focuses Readme files if one exists. If that's not enough,
                        add Images, a title and a description to your Snippet.
                    </p>
                </div>
                <div className="relative md:flex-1 order-1 md:order-1 w-full md:w-auto">
                    <div className="rounded bg-purple-400 absolute transform -rotate-3 z-0 w-full h-full top-0 left-0"></div>
                    <img src="/images/screen_readme.jpeg" alt="SnippetLand Editor" loading="lazy" className="relative w-full shadow-sm border border-purple-300" />
                </div>
            </div>

            <div className="mt-24 flex justify-center flex-wrap md:flex-nowrap max-w-6xl mx-auto">
                <div className="pt-10 md:pt-0 md:pl-8 md:flex-1 order-2 md:order-1 w-full md:w-auto">
                    <span className="block uppercase text-gray-400 text-sm">Embedding and sharing</span>
                    <h3 className="text-2xl md:text-4xl pt-4 font-extrabold">Sharing with the world</h3>
                    <p className="pt-8 text-gray-600 text-xl">
                        Use SnippetLand as platform for sharing Code Snippets with all the top features like images, Markdown
                        files and multiple files and languages.
                        <br /><br />
                        Share Snippets with other users, create public links or embed the whole Snippet Editor in websites.
                    </p>
                </div>
                <div className="relative md:flex-1 order-1 md:order-2 w-full md:w-auto">
                    <div className="rounded bg-purple-400 absolute transform -rotate-3 z-0 w-full h-full top-0 left-0"></div>
                    <img src="/images/screen_share.jpeg" alt="SnippetLand Editor" loading="lazy" className="relative w-full shadow-sm border border-purple-300" />
                </div>
            </div>

        </div>
        <div className="px-8 py-20" id="extension">

            <h2 className="text-center font-extrabold text-3xl md:text-5xl">Perfectly integrated</h2>

            <p className="mt-6 text-center text-gray-600 text-lg md:text-xl">
                Don't leave your keyboard when you're inside Visual Studio Code and still use SnippetLand.
            </p>

            <div className="mt-8 md:flex justify-center max-w-6xl mx-auto gap-9">
                <div className="flex-1">
                    <div className="rounded-lg bg-white p-3 text-center">
                        <div className="pb-3">
                            <span className="border border-gray-100 p-1 text-xs text-gray-300 mr-2">CTRL</span>
                            <span className="border border-gray-100 p-1 text-xs text-gray-300 mr-2">SHIFT</span>
                            <span className="border border-gray-100 p-1 text-xs text-gray-300 mr-2">P</span>
                        </div>
                        <h3 className="text-xl font-extrabold">Open the Command Palette</h3>
                        <p className="text-gray-600 pt-3">
                            Use the short command "SL" to run the SnippetLand Extension
                        </p>
                    </div>
                </div>
                <div className="flex-1 mt-6 md:mt-8">
                    <div className="rounded-lg bg-white p-3 text-center">
                        <div className="pb-3">
                            <span className="border border-gray-100 p-1 text-xs text-gray-300 mr-2">UP / DOWN</span>
                            <span className="border border-gray-100 p-1 text-xs text-gray-300 mr-2">ENTER</span>
                        </div>
                        <h3 className="text-xl font-extrabold">Search for a Snippet</h3>
                        <p className="text-gray-600 pt-3">
                            Choose a Snippet and in the next menu the file you want to copy
                        </p>
                    </div>
                </div>
                <div className="flex-1 mt-6 md:mt-16">
                    <div className="rounded-lg bg-white p-3 text-center">
                        <div className="pb-3">
                            <span className="border border-gray-100 p-1 text-xs text-gray-300 mr-2">CTRL / CMD</span>
                            <span className="border border-gray-100 p-1 text-xs text-gray-300 mr-2">V</span>
                        </div>
                        <h3 className="text-xl font-extrabold">Paste it in</h3>
                        <p className="text-gray-600 pt-3">
                            The file content gets copied to your clipboard and you can paste it
                        </p>
                    </div>
                </div>
            </div>

            <div className="mt-10 flex justify-center">
                <a href="https://marketplace.visualstudio.com/items?itemName=Tubitor.snippetland" target="_blank" rel="noreferrer" className="transition-all inline-block mx-auto px-8 py-5 text-xl rounded-md bg-gray-600 text-white hover:bg-gray-700 shadow-md hover:shadow-xl">
                    Install the extension
                </a>
            </div>

        </div>
        <div className="px-8 py-20 bg-white" id="pricing">

            <h2 className="text-center font-extrabold text-3xl md:text-5xl">Pricing</h2>

            <div className="md:flex justify-center md:divide-x-2">
                <div className="mx-auto md:mx-0 max-w-sm mt-6 mb-6 bg-white py-10 flex-1 text-center">
                    <span className="block font-extrabold text-lg">Basic</span>
                    <span className="block py-6">
                        <span className="font-extrabold text-6xl">Free</span>
                    </span>
                    <div className="py-3 flex justify-center items-center">
                        <span className="text-green-600 text-2xl pr-3"><IoMdCheckmarkCircleOutline /></span>
                        <span>50 Snippets each 30 days</span>
                    </div>
                    <div className="py-3 flex justify-center items-center">
                        <span className="text-green-600 text-2xl pr-3"><IoMdCheckmarkCircleOutline /></span>
                        <span>Multi-File Snippets</span>
                    </div>
                    <div className="py-3 flex justify-center items-center">
                        <span className="text-green-600 text-2xl pr-3"><IoMdCheckmarkCircleOutline /></span>
                        <span>Images in Snippets</span>
                    </div>
                    <div className="py-3 flex justify-center items-center">
                        <span className="text-green-600 text-2xl pr-3"><IoMdCheckmarkCircleOutline /></span>
                        <span>Import filess by drag&drop</span>
                    </div>
                    <div className="py-3 flex justify-center items-center">
                        <span className="text-gray-300 text-2xl pr-3"><IoMdCloseCircleOutline /></span>
                        <span>Sharing between users</span>
                    </div>
                    <div className="mt-10 flex justify-center">
                        <Link to="/app" className="transition-all inline-block mx-auto px-8 py-5 text-xl rounded-md bg-purple-600 text-white hover:bg-purple-700 shadow-md hover:shadow-xl">
                            Get started
                        </Link>
                    </div>
                </div>
                <div className="mx-auto md:mx-0 max-w-sm mt-6 mb-6 bg-white px-4 py-10 flex-1 text-center">
                    <span className="block font-extrabold text-lg">Pro</span>
                    <span className="block py-6">
                        <span className="text-2xl">€</span>
                        <span className="font-extrabold text-6xl">?</span>
                        <span className="text-xl">/mo</span>
                    </span>
                    <div className="py-3 flex justify-center items-center">
                        <span className="text-green-600 text-2xl pr-3"><IoMdCheckmarkCircleOutline /></span>
                        <span>Unlimited Snippets</span>
                    </div>
                    <div className="py-3 flex justify-center items-center">
                        <span className="text-green-600 text-2xl pr-3"><IoMdCheckmarkCircleOutline /></span>
                        <span>Multi-File Snippets</span>
                    </div>
                    <div className="py-3 flex justify-center items-center">
                        <span className="text-green-600 text-2xl pr-3"><IoMdCheckmarkCircleOutline /></span>
                        <span>Images in Snippets</span>
                    </div>
                    <div className="py-3 flex justify-center items-center">
                        <span className="text-green-600 text-2xl pr-3"><IoMdCheckmarkCircleOutline /></span>
                        <span>Import files by drag&drop</span>
                    </div>
                    <div className="py-3 flex justify-center items-center">
                        <span className="text-green-600 text-2xl pr-3"><IoMdCheckmarkCircleOutline /></span>
                        <span>Sharing between users</span>
                    </div>
                    <div className="mt-10 flex justify-center">
                        <Link to="" className="transition-all inline-block mx-auto px-8 py-5 text-xl rounded-md bg-purple-200 text-white hover:bg-purple-200 cursor-default">
                            Not available
                        </Link>
                    </div>
                </div>
            </div>

            <p className="text-center font-xs text-gray-600 max-w-2xl mx-auto">
                If the Basic-Plan isn't enough for you and you're interested in the Pro-Plan, please contact us:&nbsp;
                <a href="mailto:team@snippet.land" className="font-bold text-purple-600">team@snippet.land</a> <br />
                We love to support cool projects and dev-teams, so there's a good chance you'll get SnippetLand Pro for
                free for your team :)
            </p>

        </div>
    </>
}
