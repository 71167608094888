export default {

    success: (title, desc) => {
        showToast("bg-green-600", title, desc)
    },

    error: (title, desc) => {
        showToast("bg-red-600", title, desc)
    },

    warning: (title, desc) => {
        showToast("bg-orange-600", title, desc)
    },

    info: (title, desc) => {
        showToast("bg-purple-600", title, desc)
    },

}

function showToast(color, title, desc) {
    const element = document.createElement("div")
    element.setAttribute("class", "rounded " + color + " text-white p-4 fixed top-3 z-50 cursor-pointer")
    element.setAttribute("style", "left: 50%; transform: translateX(-50%); max-width: 90%;transition: .2s linear;")
    element.innerHTML = `<span><b>${title}:</b> ${desc}</span>`
    document.querySelector("#root").appendChild(element)
    let i = 0, duration = 3, timer = setInterval(() => {
        i += 0.2
        element.style.opacity = 1 - i / duration
        if (i >= duration) {
            element.remove()
            clearInterval(timer)
        }
    }, 200)
}